import { isServerSide } from '@brick/utils';
import { setAuthHeader } from 'apps/client/lib/api/api.utils';
import {
  EXPIRATION_TIME_IN_MS,
  JWT_EXPIRATION_TIME_LS_KEY,
  JWT_LS_KEY,
  USER_LS_KEY,
} from '../const/auth.const';
import { Auth } from '../model/auth';

export const performClientSideLogin = () => {
  if (isServerSide()) {
    return;
  }

  const jwt = localStorage.getItem(JWT_LS_KEY);
  const expirationTime = Number(
    localStorage.getItem(JWT_EXPIRATION_TIME_LS_KEY)
  );

  if (expirationTime < Date.now()) {
    console.log('JWT EXPIRED');
    return;
  }
  const user = JSON.parse(localStorage.getItem(USER_LS_KEY));

  if (!(jwt && user)) {
    return;
  }

  console.log('USER ALREADY LOGGED IN.');
  setAuthHeader(jwt);
  return {
    jwt,
    user,
  };
};

export const performClientSideLogout = () => {
  localStorage.removeItem(JWT_LS_KEY);
  localStorage.removeItem(JWT_EXPIRATION_TIME_LS_KEY);
  localStorage.removeItem(USER_LS_KEY);
  console.log('USER LOGGED OUT.');
  window.location.href = '/';
};

export const mapDataToAuth = (data: any) => {
  const { provider, confirmed, blocked, createdAt, updatedAt, ...user } =
    data.data.user;

  const auth: Auth = {
    jwt: data.data.jwt,
    user: user,
  };
  return auth;
};

export const handleAuthSuccess = (auth) => {
  console.log('USER JUST LOGGED IN');
  localStorage.setItem(JWT_LS_KEY, auth.jwt);
  localStorage.setItem(
    JWT_EXPIRATION_TIME_LS_KEY,
    (Date.now() + EXPIRATION_TIME_IN_MS).toString()
  );
  localStorage.setItem(USER_LS_KEY, JSON.stringify(auth.user));
  setAuthHeader(auth.jwt);
};
