import { api } from "./api";

export const setAuthHeader = (token: string) => {
  api.defaults.headers.common['authorization'] = 'Bearer ' + token;
};

export const removeAuthHeader = () => {
  const { authorization, ...rest } = api.defaults.headers.common;
  api.defaults.headers.common = rest;
};
