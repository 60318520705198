import { User } from 'apps/client/lib/features/auth/model/auth';
import { performClientSideLogin } from 'apps/client/lib/features/auth/utils/auth.utils';
import { createContext, ReactNode, useContext } from 'react';

export interface UserProviderProps {
  children: ReactNode | ReactNode[];
}

const UserContext = createContext<User | undefined>(undefined);

export const useUser = () => {
  return useContext(UserContext);
};

export const isLoggedIn = () => {
  const user = useContext(UserContext);
  return !!user;
};

export const UserProvider = (props: UserProviderProps) => {
  const { children } = props;
  const auth = performClientSideLogin();
  return (
    <UserContext.Provider value={auth?.user}>{children}</UserContext.Provider>
  );
};
