import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  colors: {
    primary: { 500: '#FFB802', 600: '#f3af01' },
    black: { 500: '#434242', 600: '#434242e6' },
    grey: {
      500: '#918E8F',
      400: '#C4C4C4',
      300: '#eaeaea',
      200: '#f4f4f4',
      100: '#F9F9F9',
    },
  },
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.7rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '3.75rem',
    '7xl': '4.5rem',
    '8xl': '6rem',
    '9xl': '8rem',
  },
  sizes: {
    container: {
      sm: '640px',
      md: '768px',
      lg: '840px',
      xl: '1128px',
    },
  },
});
