import { isServerSide } from './general';
export const stripHtml = (html: string) => {
  if (isServerSide()) {
    return '';
  }
  if (!html) {
    return '';
  }
  let tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};
