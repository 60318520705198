import { useRouter } from 'next/router';

export const getFirstSlugFromPath = (path: string) =>
  path.slice(1).split('/')[0];

export const useIsRouteActive = (url: string, exact = false) => {
  const router = useRouter();

  // handle first slug match
  if (
    !exact &&
    getFirstSlugFromPath(router.asPath) === getFirstSlugFromPath(url)
  ) {
    return true;
  }

  // handle exact match
  if (router.pathname === url) return true;
  return false;
};
