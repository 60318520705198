import { ChakraProvider } from '@chakra-ui/react';
import { AppProps } from 'next/app';
import Script from 'next/script';
import React from 'react';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { theme } from '../../../libs/ui-components/src/lib/Theme/theme';
import { UserProvider } from '../components/Licitation/Providers/UserProvider';
import '../styles/globals.css';
import '../styles/react-slick-custom-theme.css';

function CustomApp({ Component, pageProps }: AppProps) {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // staleTime: Infinity,
            // refetchOnWindowFocus: false,
          },
        },
      })
  );

  return (
    <>
      <Script
        strategy="lazyOnload"
        src={'https://www.googletagmanager.com/gtag/js?id=G-3DWEB5GYMS'}
      />
      <Script id="google-analytics-script" strategy="lazyOnload">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-3DWEB5GYMS');
        `}
      </Script>

      <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps?.dehydratedState}>
            {/* <CookieBot domainGroupId={'677e36a7-cd99-4089-bbf5-faf55ce00e3c'}  /> */}
            <ReactQueryDevtools initialIsOpen={true} />
            <UserProvider>
              <Component {...pageProps} />
            </UserProvider>
          </Hydrate>
        </QueryClientProvider>
      </ChakraProvider>
    </>
  );
}

export default CustomApp;
